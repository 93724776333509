import React, { useEffect, useState } from 'react'
import { reportAction } from '../../../store/actions/reportAction'
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from './Navbar';
import Footer from '../../Component/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { RC_SITE_KEY } from "../../../config/keys"

export default function RequestReport() {
    useEffect(() => {
        document.body.classList.add('full-height');
        document.body.classList.add('page-report');

        const handleMouseMove = (e) => {
            // Calculate the offset in the opposite direction
            const xOffset = (window.innerWidth / 2 - e.clientX) / 20;
            const yOffset = (window.innerHeight / 2 - e.clientY) / 20;

            setOffset({ x: xOffset, y: yOffset });
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            document.body.classList.remove('full-height');
            document.body.classList.remove('page-report');

            window.removeEventListener("mousemove", handleMouseMove);
        }
    }, [])

    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { reportError, successMessage } = useSelector((state) => state.authReport);
    const recaptchaRef = React.useRef();

    const [reportState, setReportState] = React.useState({
        email: ''
    })

    const inputHendle = (e) => {
        setReportState({
            ...reportState,
            [e.target.name]: e.target.value
        })
    }
    const [loadings, setLoadings] = useState(false)

    // console.log(reportState)

    const emailSubmit = async (e) => {
        e.preventDefault()
        setLoadings(true)
        const token = await recaptchaRef.current.executeAsync();
        reportState.rc_token = token
        dispatch(reportAction(reportState)).then(() => {
            setLoadings(false)
            setReportState({
                email: ''
            })
        })
    }

    useEffect(() => {
        if (reportError) {
            setTimeout(() => { dispatch({ type: "REPORT_ERROR_CLEAR" }); }, 3000)
        }
        if (successMessage) {
            setTimeout(() => { dispatch({ type: "REPORT_SUCCESS_MESSAGE_CLEAR" }); }, 3000)
        }
    }, [reportError, successMessage])



    let imageArr = [
        {
            image: '../images/new-wallpapers/reportn.jpg',
            text: `Hello there, this page creates clarity and transparency`,
            color: '#000000'
        },
        /* {
            image: '../images/new-wallpapers/report.jpg',
            text: 'Putting the fun back in team structuring, since 2021.',
            color: '#000000'
        },
        {
            image: '../images/new-wallpapers/report.jpg',
            text: `Sweet teams are made of this. Your transformation won't disagree.`,
            color: '#000000'
        } */
    ]

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }


    // let objNumber = getRandomInt(3)
    // document.body.style = 'overflow: hidden';
    document.body.style.backgroundImage = `url(${imageArr[0].image})`;

    return (
        <div>
            {/* <!-- NAVBAR --> */}
            <Navbar />
            {/* <!-- END NAVBAR --> */}

            <div className="container vertical-center">
                <div className="row">
                    <div className="col-md-7 col-sm-12">
                        <div className="flex-caption2">
                            <div className="structuring-lo">
                                <h4 style={{ "color": '#000000' }}>Hello there, this page creates clarity and transparency</h4>
                                <p style={{ "color": '#000000' }}>As existing team member, you can request a link to your personal report,<br />containing all roles, skills, domains and links that have been attributed to you.</p>
                                {/* <a onClick={() => navigate('/pricing')} href="#" className="button button-darkgrey-v46">Create account</a> */}
                                <a target="_blank" rel="noopener nofollow" href="https://teamdecoder.kb.help/reports/" className="button button-white-v46">All about reports</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 col-sm-12 mb-5"></div>
                    <div className="col-md-4 col-sm-12">
                        <div className="login-from">
                            <img alt="" className="vector-0-el el-top" src='../images/new-wallpapers/report-group-top.png' style={{
                                transform: `translate(${offset.x}px, ${offset.y}px)`,
                            }} />
                            <img alt="" className="vector-0-el el-bottom" src='../images/new-wallpapers/report-group-bottom.png' style={{
                                transform: `translate(${offset.x}px, ${offset.y}px)`,
                            }} />

                            <form onSubmit={emailSubmit} id="mytpt-report-request">
                                {successMessage ?
                                    <div className="alert alert-success" style={{ "width": "100%" }}>{successMessage}</div>
                                    : null}
                                {reportError ?
                                    <div className="alert alert-danger" style={{ "width": "100%" }}>{reportError}</div>
                                    : null}
                                <h3>Enter your email to receive your report</h3>
                                <div className="alert" style={{ "display": "none", "width": "100%" }}></div>
                                <input type="email" name='email' value={reportState.email} onChange={(e) => inputHendle(e)} className="form-control" id="email" placeholder="Email"
                                    autoComplete="off" />
                                <button type="submit" className="g-recaptch">
                                    {loadings ?
                                        <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000", marginRight: '10%' }} />
                                        : null}
                                    Request Report
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RC_SITE_KEY}
            />
        </div>
    )
}
