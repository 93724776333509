import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { userLogin } from '../../../store/actions/authAction';
import { useAlert } from "react-alert";
// import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from '../../../../store/types/teamType'
import { useLocation, useNavigate } from "react-router-dom";
import {
  createTeam,
  updateTeam,
  updateManyTeams,
  getTeam,
  copyAction,
  teamAction,
  deleteTeam,
  getTemplate,
} from "../../../../store/actions/teamAction";
import { SERVER_URI } from "../../../../config/keys";
import { createState, getState } from "../../../../store/actions/stateAction";

import { TEAM_SUCCESS_CLEAR } from "../../../../store/types/teamType";

import { SyncLoader } from "react-spinners";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import Swal from "sweetalert2";
import { TEAM_LIMIT } from "../../../../config/keys";
import { generateStripeUpgradeUrl } from "../../../../store/actions/pricingAction";
import {
  freezeWindow,
  unfreezeWindow,
} from "../../../../HelperFunctions/Overlay";

export default function EditTemplateModal({
  closeEditTemplate,
  load,
  setLoad,
  template,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // closeEditTemplate
  const { loading, successMessage, error, authenticate, myInfo, curUserData } =
    useSelector((state) => state.auth);
  const { teams, sMessage, errors } = useSelector((state) => state.teams);


  const [state, setState] = useState([]);

  useEffect(() => {
    if (teams && teams.length > 0) {
      let curTeams = [];
      for (let i = 0; i < teams.length; i++) {
        let obj = {
          _id: teams[i]._id,
          userId: teams[i].userId,
          team_name: teams[i].team_name,
          archived: teams[i].archived,
        };
        curTeams.push(obj);
      }
      setState(curTeams);
    }
  }, [teams]);


  const copyTeam = (data) => {
    if (
      curUserData &&
      Object.keys(curUserData).length > 0 &&
      curUserData.plan === "FREE" &&
      teams.length >= TEAM_LIMIT
    ) {
      Swal.fire({
        // // icon: "info",
        title: "Maximize Your Team's Potential!",
        showCancelButton: true,
        text: "You've hit the capacity limit for the free version of our software. Ready to grow further? Upgrade to a GO account for the freedom to add unlimited teams and team members!",
        confirmButtonText: "Upgrade now",
        cancelButtonText: `Close`,
        cancelButtonColor: "#EE7364",
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.href = `${CLIENT_URL}/pricing`
          dispatch(
            generateStripeUpgradeUrl({
              userId: curUserData._id,
              plan: "GO",
              interval: "month",
              email: curUserData.email,
            })
          );
        }
      });
    } else {
      setLoad(true);
      freezeWindow(false, true);
      dispatch(
        copyAction({
          adminId: myInfo.userId,
          teamId: data._id,
          templateCopy: true,
          url: `${SERVER_URI}/team/copy/create`,
        })
      ).then(() => {
        dispatch(getTeam({ userId: myInfo.userId })).then(() => {
          setLoad(false);
          unfreezeWindow();
        });
        dispatch({ type: TEAM_SUCCESS_CLEAR });
      });
    }
  };


  let stateTeam = [];

  if (teams && teams.length > 0) {
    for (let team of teams) {
      stateTeam.push({ email: "" });
    }
  }


  useEffect(() => {
    if (sMessage && sMessage.length > 0) {
      dispatch({ type: TEAM_SUCCESS_CLEAR });
      closeEditTemplate();
      setLoad(false);
      Swal.fire({
        title: "Excellent",
        text: "The template has been downloaded into your teams list!",
        confirmButtonText: "Ok",
      });
    }
  }, [sMessage, errors]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");

  return (
    <div
      id="templatesModal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Templates
              <a style={{ float: "right", textDecoration: "underline", color: "black", fontWeight: "normal", fontSize: "1.2rem" }} href="https://teamdecoder.tawk.help/article/working-with-templates" rel="noopener nofollow" target="_blank">
                More Info
              </a>
            </h4>
          </div>
          <div className="modal-body">
            {template &&
              template.length > 0 &&
              template.map((template, index) => (
                <div className="row" key={index + 1}>
                  <div className="col-xs-11">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="team_name"
                        style={{ pointerEvents: "none" }}
                        value={template.team_name}
                      />
                    </div>
                  </div>

                  <div className="col-xs-1" style={{ cursor: "pointer" }}>
                    <Tippy content={<span>Download</span>}>
                      <i
                        className="fas fa-download"
                        onClick={() => copyTeam(template)}
                      />
                    </Tippy>
                  </div>
                </div>
              ))}
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-12 text-right">
                <Tippy content={<span>Close Tool</span>}>
                  <button
                    type="button"
                    onClick={closeEditTemplate}
                    className="btn btn-mytpt btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
