import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { userLogin } from '../../../store/actions/authAction';
import { useAlert } from "react-alert";
// import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from '../../../../store/types/teamType'
import { useLocation, useNavigate } from "react-router-dom";
import {
  createTeam,
  updateTeam,
  updateManyTeams,
  getTeam,
  copyAction,
  teamAction,
  deleteTeam,
  getAllTeamData,
} from "../../../../store/actions/teamAction";
import { SERVER_URI } from "../../../../config/keys";
import { createState, getState } from "../../../../store/actions/stateAction";

import { TEAM_ERROR_CLEAR, TEAM_SUCCESS_CLEAR } from "../../../../store/types/teamType"

import { SyncLoader } from "react-spinners"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import Swal from "sweetalert2";
import { TEAM_LIMIT, CLIENT_URL } from "../../../../config/keys"
import { generateStripeUpgradeUrl } from "../../../../store/actions/pricingAction";
import { freezeWindow, unfreezeWindow } from "../../../../HelperFunctions/Overlay";

export default function Team({ closeTeam, load, setLoad }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const { loading, successMessage, error, authenticate, myInfo, curUserData } = useSelector((state) => state.auth);
  const { teams, sMessage, errors } = useSelector((state) => state.teams);

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");

  const [formValues, setFormValues] = useState([{ team_name: "" }]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { team_name: "" }]);
  };


  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };


  // console.log("50", teams)
  // console.log("51", formValues)



  const [state, setState] = useState([]);


  useEffect(() => {
    if (teams && teams.length > 0) {
      let curTeams = []
      for (let i = 0; i < teams.length; i++) {
        let obj = {
          _id: teams[i]._id,
          userId: teams[i].userId,
          team_name: teams[i].team_name,
          archived: teams[i].archived,
        }
        curTeams.push(obj)
      }
      setState(curTeams);
    }

  }, [teams]);

  const inputHendle = (index, e) => {
    // console.log(e.target.checked);
    let newFormValues = [...state];
    if (e.target.name === "archived") {
      newFormValues[index][e.target.name] = e.target.checked;
      setState(newFormValues);
    } else {
      newFormValues[index][e.target.name] = e.target.value;
      setState(newFormValues);
    }

  };


  const newAddTeam = () => {
    if (curUserData && Object.keys(curUserData).length > 0 && curUserData.plan === "FREE") {
      if (teams.length >= TEAM_LIMIT) {
        Swal.fire({
          // // icon: "info",
          title: "Maximize Your Team's Potential!",
          showCancelButton: true,
          text: "You've hit the capacity limit for the free version of our software. Ready to grow further? Upgrade to a GO account for the freedom to add unlimited teams and team members!",
          confirmButtonText: 'Upgrade now',
          cancelButtonText: `Close`,
          cancelButtonColor: "#EE7364"
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = `${CLIENT_URL}/pricing`
            dispatch(generateStripeUpgradeUrl({ userId: curUserData._id, plan: 'GO', interval: 'month', email: curUserData.email }))
          }
        });
      } else if (teams.length < TEAM_LIMIT) {
        return true
      }
    } else {
      return true
    }
  }

  const handleSubmit = (event) => {
    let obj = {};
    let datas = {};
    if (myInfo.role === "Admin") {
      obj.adminId = myInfo.AdminId;
      datas.adminId = myInfo.AdminId;
    } else {
      obj.adminId = myInfo.userId;
      datas.adminId = myInfo.userId;
    }

    obj.url = `${SERVER_URI}/team/create`;
    datas.url = `${SERVER_URI}/team/update-many-teams`;
    // obj.data = formValues;
    datas.data = [...state];

    if (datas && datas.data && datas.data.length > 0) {
      setLoad(true)
      dispatch(updateManyTeams(datas)).then(() => {
        dispatch(getTeam({ userId: myInfo.userId }));
      })
    }

    let newTeamData = []
    if (formValues && formValues.length > 0) {
      for (let i = 0; i < formValues.length; i++) {
        if (formValues[i].team_name && formValues[i].team_name.trim().length > 0) {
          newTeamData.push(formValues[i])
        }
      }
    }
    obj.data = newTeamData
    if (obj.data.length > 0) {
      let status = newAddTeam()
      if (status && status === true) {
        setLoad(true)
        dispatch(createTeam(obj)).then(() => {
          dispatch(getTeam({ userId: myInfo.userId }));
          setFormValues([{ team_name: "" }])
        });
      }
    }
  };

  const copyTeam = (data) => {
    if (curUserData && Object.keys(curUserData).length > 0 && curUserData.plan === "FREE" && teams.length >= TEAM_LIMIT) {
      Swal.fire({
        // // icon: "info",
        title: "Maximize Your Team's Potential!",
        showCancelButton: true,
        text: "You've hit the capacity limit for the free version of our software. Ready to grow further? Upgrade to a GO account for the freedom to add unlimited teams and team members!",
        confirmButtonText: 'Upgrade now',
        cancelButtonText: `Close`,
        cancelButtonColor: "#EE7364"
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.href = `${CLIENT_URL}/pricing`
          dispatch(generateStripeUpgradeUrl({ userId: curUserData._id, plan: 'GO', interval: 'month', email: curUserData.email }))
        }
      });
    } else {
      setLoad(true)
      freezeWindow(false);
      dispatch(
        copyAction({
          adminId: myInfo.userId,
          teamId: data._id,
          url: `${SERVER_URI}/team/copy/create`,
        })
      ).then(() => {
        dispatch(
          getTeam({ userId: myInfo.userId })
        ).then(() => {
          setLoad(false)
          unfreezeWindow();
        });
        dispatch({ type: TEAM_SUCCESS_CLEAR })
      });
    }
  };


  const [tTeam, setTTeam] = useState("");

  const transferTeam = (index, e, isDisabled) => {
    if (isDisabled) {
      Swal.fire({
        title: "Transfer not possible",
        text: "Cannot transfer currently active team!"
      })
    } else {
      document.getElementById(`from_id_${index}`).style.display = "block";
      setTTeam(e._id);
    }
  };

  const transferDelete = (index, e, isDisabled) => {
    if (isDisabled) {
      Swal.fire({
        title: "Delete not possible",
        text: "Cannot delete currently active team!"
      })
      return;
    }

    // if (mode && mode === "god") {
    Swal.fire({
      // // icon: "info",
      title: "Are you sure?",
      showCancelButton: true,
      text: "This action will permanently delete this team and all its data!",
      confirmButtonText: 'Yes',
      cancelButtonText: `No`,
      cancelButtonColor: "#EE7364"
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(index, e, isDisabled)
        dispatch(deleteTeam({
          adminId: myInfo.userId,
          teamId: e._id,
          url: `${SERVER_URI}/team/delete`,
        }))
      }
    });
    // }
  }

  const transferTeamClear = (index) => {
    document.getElementById(`from_id_${index}`).style.display = "none";
    setTTeam("");
  };

  let stateTeam = [];

  if (teams && teams.length > 0) {
    for (let team of teams) {
      stateTeam.push({ email: "" });
    }
  }

  const [teamState, setTeamState] = React.useState([...stateTeam]);

  // console.log(teamState)

  const inputHendleTeam = (e, index) => {
    let emailData = [...teamState];
    emailData[index][e.target.name] = e.target.value;
    setTeamState(emailData);
  };

  const transferTeamSubmit = (e, index) => {
    freezeWindow(false);
    setLoad(true)
    dispatch(
      teamAction({
        adminId: myInfo.userId,
        teamId: tTeam.toString(),
        url: `${SERVER_URI}/team/transfer/create`,
        emailId: teamState[index].email,
      })
    ).then(() => {
      dispatch(getTeam({ userId: myInfo.userId }));
      setLoad(false)
      setTTeam("");
      document.getElementById(`from_id_${index}`).style.display = "none";
      unfreezeWindow();
    });
  };
  // console.log(teams)

  useEffect(() => {
    if (sMessage && sMessage.length > 0) {
      Swal.fire({
        title: "Success",
        text: sMessage,
      })
      dispatch({ type: TEAM_SUCCESS_CLEAR })
      setLoad(false);
      closeTeam()
    }
    if (errors) {
      if (errors === `Transfer to FREE account restricted.`) {
        Swal.fire({
          // icon: "info",
          title: "Transfer not possible",
          showCancelButton: false,
          text: "You cannot transfer teams into FREE accounts. Please tell the recipient to upgrade their account!",
          confirmButtonText: "Ok",
          // cancelButtonText: `Close`,
          // cancelButtonColor: "#EE7364",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: errors,
        })
      }

      dispatch({ type: TEAM_ERROR_CLEAR })
      setLoad(false);
      // closeTeam()
    }
  }, [sMessage, errors])

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode');

  return (
    <div
      id="teamsModal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">

        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Edit Teams</h4>
          </div>
          <div className="modal-body">
            <form>
              <div id="myRepeatingFields">
                <div className="row">
                  <div className="col-xs-9">
                    <label>Team Name</label>
                  </div>
                  <div className="col-xs-3 text-left">
                    <label>Archived?</label>
                  </div>
                  {/* <div className="col-xs-1"></div> */}
                </div>
                {state.map((e, index) => {
                  // console.log(e)
                  let isDisabled = false
                  if (curUserData && curUserData.curTeam && curUserData.curTeam.length > 0) {
                    if (curUserData.curTeam === e._id) {
                      isDisabled = true
                    }
                  }

                  return (
                    <div key={index} className="entry row" style={{ /* display: "flex",  */alignItems: "center", marginBottom: "10px" }}>
                      <div className="col-xs-9">
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <input
                            type="text"
                            className="form-control"
                            name="team_name"
                            id="62"
                            style={isDisabled ? { color: "#00a67e" } : {}}
                            value={e.team_name}
                            placeholder="Team Name (required)"
                            onChange={(e) => inputHendle(index, e)}
                            autoComplete="off"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-xs-3" style={{ cursor: "pointer" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItem: "center" }}>
                          <input
                            type="checkbox"
                            name="archived"
                            disabled={isDisabled}
                            checked={e.archived ? e.archived : false}
                            onChange={(e) => inputHendle(index, e)}
                            style={{ position: 'unset' }}
                          />
                          <Tippy content={<span>Copy</span>}>
                            <img
                              className="clone-icon"
                              onClick={() => copyTeam(e)}
                              src="./images/icon-clone-2.png"
                            ></img>
                          </Tippy>
                          <Tippy content={<span>Transfer</span>}>
                            <img
                              className="transfer-icon"
                              onClick={() => transferTeam(index, e, isDisabled)}
                              src="./images/transfer-icon.png"
                            ></img>
                          </Tippy>
                          <Tippy content={<span>Delete</span>}>
                            <img
                              className="delete-icon"
                              onClick={() => transferDelete(index, e, isDisabled)}
                              src="./images/newicons/delete.svg"
                            ></img>
                          </Tippy>

                        </div>
                      </div>


                      <div
                        id={`from_id_${index}`}
                        className="col-xs-12 transfer-box"
                        style={{ display: "none" }}
                      >
                        <div className="row">
                          <div
                            className="col-xs-8"
                            style={{ paddingRight: "5" }}
                          >
                            <div className="form-group">
                              <input
                                type="text"
                                name="email"
                                className="form-control target_email"
                                // value={teamState[index].email}
                                placeholder="Enter Email Address"
                                onChange={(e) => inputHendleTeam(e, index)}
                                autoComplete="off"
                                required={true}
                                style={{ backgroundColor: "#fff" }}
                              />
                            </div>
                          </div>
                          <div
                            className="col-xs-2"
                            style={{ paddingLeft: "0" }}
                          >
                            <Tippy content={<span>Transfer Now</span>}>
                              <button
                                type="button"
                                onClick={() => transferTeamSubmit(e, index)}
                                className="btn btn-mytpt process-transfer"
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </Tippy>
                            <Tippy content={<span>Cancel Transfer</span>}>
                              <button
                                type="button"
                                onClick={() => transferTeamClear(index)}
                                className="btn btn-mytpt btn-mytpt-red cancel-transfer"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </Tippy>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {formValues.map((element, index) => (
                <div className="row entry" key={index}>
                  <div className="col-sm-9">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Team Name"
                        name="team_name"
                        value={element.team_name || ""}
                        className="form-control"
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    {index ? (
                      <button
                        type="button"
                        className="button remove btn btn-remove btn-mytpt-red"
                        onClick={() => removeFormFields(index)}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
              <button
                type="button"
                title="Add New Team"
                onClick={() => addFormFields()}
                className="btn btn-mytpt"
                id="add-new-team"
              >
                Add New Team
              </button>
            </form>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-12 text-right">
                <Tippy content={<span>Close Tool</span>}>
                  <button
                    type="button"
                    onClick={closeTeam}
                    className="btn btn-mytpt btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </Tippy>
                <Tippy content={<span>Save And Close</span>}>
                  <button
                    type="button"
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="btn btn-mytpt save-teams"
                  >
                    <i className="fa fa-check"></i>
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
          {/* <SyncLoader color="#36d7b7" /> */}
        </div>
      </div>
    </div>
  );
}
